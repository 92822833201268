exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dla-klientow-biznesowych-tsx": () => import("./../../../src/pages/dla-klientow-biznesowych.tsx" /* webpackChunkName: "component---src-pages-dla-klientow-biznesowych-tsx" */),
  "component---src-pages-dla-klientow-indywidualnych-tsx": () => import("./../../../src/pages/dla-klientow-indywidualnych.tsx" /* webpackChunkName: "component---src-pages-dla-klientow-indywidualnych-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kancelaria-tsx": () => import("./../../../src/pages/kancelaria.tsx" /* webpackChunkName: "component---src-pages-kancelaria-tsx" */),
  "component---src-pages-kim-jestesmy-alicja-weide-tsx": () => import("./../../../src/pages/kim-jestesmy/alicja-weide.tsx" /* webpackChunkName: "component---src-pages-kim-jestesmy-alicja-weide-tsx" */),
  "component---src-pages-kim-jestesmy-anna-sklinda-maciejewska-tsx": () => import("./../../../src/pages/kim-jestesmy/anna-sklinda-maciejewska.tsx" /* webpackChunkName: "component---src-pages-kim-jestesmy-anna-sklinda-maciejewska-tsx" */),
  "component---src-pages-kim-jestesmy-index-tsx": () => import("./../../../src/pages/kim-jestesmy/index.tsx" /* webpackChunkName: "component---src-pages-kim-jestesmy-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-wynagrodzenie-index-tsx": () => import("./../../../src/pages/wynagrodzenie/index.tsx" /* webpackChunkName: "component---src-pages-wynagrodzenie-index-tsx" */),
  "component---src-pages-wynagrodzenie-klienci-biznesowi-tsx": () => import("./../../../src/pages/wynagrodzenie/klienci-biznesowi.tsx" /* webpackChunkName: "component---src-pages-wynagrodzenie-klienci-biznesowi-tsx" */),
  "component---src-pages-wynagrodzenie-klienci-indywidualni-tsx": () => import("./../../../src/pages/wynagrodzenie/klienci-indywidualni.tsx" /* webpackChunkName: "component---src-pages-wynagrodzenie-klienci-indywidualni-tsx" */)
}

